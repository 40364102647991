var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"sandbox"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.resetPwdDialog),callback:function ($$v) {_vm.resetPwdDialog=$$v},expression:"resetPwdDialog"}},[_c('v-window',{staticClass:"bgDialog",model:{value:(_vm.resetStep),callback:function ($$v) {_vm.resetStep=$$v},expression:"resetStep"}},[_c('v-window-item',{attrs:{"value":'new-pwd-confirm'}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"required":"","label":"Senha","rules":[_vm.cantEmptyRule, _vm.passwordValidRule],"type":"password"},model:{value:(_vm.pwd),callback:function ($$v) {_vm.pwd=$$v},expression:"pwd"}}),_c('v-text-field',{attrs:{"required":"","label":"Confirme a senha","rules":[
                _vm.cantEmptyRule,
                _vm.passwordConfirmationRule,
                _vm.passwordValidRule ],"type":"password"},model:{value:(_vm.pwdConfirm),callback:function ($$v) {_vm.pwdConfirm=$$v},expression:"pwdConfirm"}}),_c('span',{staticClass:"caption"},[_vm._v("Por favor, insira uma senha para sua conta")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid,"depressed":""},on:{"click":_vm.sendCode}},[_vm._v("Avançar")])],1)],1)],1),_c('v-window-item',{attrs:{"value":'code'}},[_c('v-form',{model:{value:(_vm.validCode),callback:function ($$v) {_vm.validCode=$$v},expression:"validCode"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"rules":[
                function (e) { return !!e || 'Digite o codigo enviado para o email'; },
                function (e) { return e.length === 6 || 'O codigo contém 6 digitos'; } ]},model:{value:(_vm.codeValue),callback:function ($$v) {_vm.codeValue=$$v},expression:"codeValue"}}),_c('span',{staticClass:"caption grey--text text--darken-1"},[_vm._v("Insira o código de confirmação enviado para o seu e-mail")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.validCode,"depressed":""},on:{"click":function($event){return _vm.changePwd()}}},[_vm._v("Confirmar")])],1)],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"clipped":_vm.drawer.clipped,"floating":_vm.drawer.floating,"mini-variant":_vm.drawer.mini,"permanent":_vm.drawer.type === 'permanent',"temporary":_vm.drawer.type === 'temporary',"color":_vm.colors.menu,"app":"","overflow":""},model:{value:(_vm.drawer.model),callback:function ($$v) {_vm.$set(_vm.drawer, "model", $$v)},expression:"drawer.model"}},[(_vm.colors.isMenuLogoAllowed === true)?_c('v-img',{attrs:{"src":this.menuLogo}}):_vm._e(),_c('v-treeview',{attrs:{"items":_vm.model.children,"activatable":"","open-on-click":"","active":_vm.active,"return-object":"","color":_vm.colors.text},on:{"update:active":[function($event){_vm.active=$event},function($event){return _vm.click(_vm.active)}]},scopedSlots:_vm._u([{key:"label",fn:function(ref){
              var item = ref.item;
return [(item.icon)?_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.colors.icons}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({ color: _vm.colors.text })},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}}])})],1),_c('v-app-bar',{attrs:{"clipped-left":_vm.drawer.clipped,"color":_vm.colors.appBar,"app":""}},[(_vm.drawer.type !== 'permanent')?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer.model = !_vm.drawer.model}}},[_c('v-icon',{attrs:{"color":_vm.colors.icons}},[_vm._v("mdi-menu")])],1):_vm._e(),_c('v-toolbar-title',[_c('span',{style:({ color: _vm.colors.text })},[_vm._v(_vm._s(this.header))])]),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.colors.icons}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.openDialog}},[_c('v-list-item-title',[_vm._v("Alterar senha")])],1),_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-list-item-title',[_vm._v("Sair")])],1)],1)],1)],1),_c('v-content',{attrs:{"fluid":"","id":"content_panel"}},[(
        this.$store.state.trustedUrl === null &&
        (this.$store.state.folder === undefined ||
          this.$store.state.view === undefined)
      )?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Selecione um dashboard no menu lateral")])])],1):_c('router-view')],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout},model:{value:(_vm.snackbar.active),callback:function ($$v) {_vm.$set(_vm.snackbar, "active", $$v)},expression:"snackbar.active"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"text":"","outlined":""},on:{"click":function($event){_vm.snackbar.active = false}}},[_vm._v("Fechar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }