<template>
  <v-app id="sandbox">
    <v-dialog width="500" v-model="resetPwdDialog">
      <v-window class="bgDialog" v-model="resetStep">
        <v-window-item :value="'new-pwd-confirm'">
          <v-form v-model="valid">
            <v-card-text>
              <v-text-field
                required
                label="Senha"
                :rules="[cantEmptyRule, passwordValidRule]"
                type="password"
                v-model="pwd"
              ></v-text-field>
              <v-text-field
                required
                label="Confirme a senha"
                v-model="pwdConfirm"
                :rules="[
                  cantEmptyRule,
                  passwordConfirmationRule,
                  passwordValidRule,
                ]"
                type="password"
              ></v-text-field>
              <span class="caption"
                >Por favor, insira uma senha para sua conta</span
              >
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="closeDialog" text>Cancelar</v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" depressed @click="sendCode"
                >Avançar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-window-item>
        <v-window-item :value="'code'">
          <v-form v-model="validCode">
            <v-card-text>
              <v-text-field
                :rules="[
                  (e) => !!e || 'Digite o codigo enviado para o email',
                  (e) => e.length === 6 || 'O codigo contém 6 digitos',
                ]"
                v-model="codeValue"
              ></v-text-field>
              <span class="caption grey--text text--darken-1"
                >Insira o código de confirmação enviado para o seu e-mail</span
              >
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!validCode" @click="changePwd()" depressed
                >Confirmar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-window-item>
      </v-window>
    </v-dialog>
    <v-navigation-drawer
      v-model="drawer.model"
      :clipped="drawer.clipped"
      :floating="drawer.floating"
      :mini-variant="drawer.mini"
      :permanent="drawer.type === 'permanent'"
      :temporary="drawer.type === 'temporary'"
      :color="colors.menu"
      app
      overflow
    >
      <v-img
        v-if="colors.isMenuLogoAllowed === true"
        :src="this.menuLogo"
      ></v-img>
      <v-treeview
        :items="model.children"
        activatable
        open-on-click
        :active.sync="active"
        return-object
        :color="colors.text"
        @update:active="click(active)"
      >
        <template v-slot:label="{ item }">
          <v-icon v-if="item.icon" class="mr-3" :color="colors.icons">{{
            item.icon
          }}</v-icon>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" :style="{ color: colors.text }">{{
                item.name
              }}</span>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="drawer.clipped" :color="colors.appBar" app>
      <v-app-bar-nav-icon
        v-if="drawer.type !== 'permanent'"
        @click.stop="drawer.model = !drawer.model"
      >
        <v-icon :color="colors.icons">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <span :style="{ color: colors.text }">{{ this.header }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :color="colors.icons">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="openDialog">
            <v-list-item-title>Alterar senha</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logOut">
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-content fluid id="content_panel">
      <!-- <v-row v-if="message1Visible" align="center" justify="center">
      <v-col>
        <h5 class="text-center white--text" style="background-color: black; line-height: 2.0; margin-left: 10px; margin-right: 10px;"> <img src="@/pad_ico_atencao_10x10.png" alt="Ícone" class="mr-2" style="height: 15px; width: 15px; vertical-align: middle;"> <strong>AVISO:</strong> 
        <span style="font-weight: normal;"> A partir do dia 30 de janeiro, as mensagens de e-mail do PAD serão enviadas através do remetente</span> qwst@mail.pad.questmanager.com. 
        <span style="font-weight: normal;"> <p>Caso seja necessário, verifique com o TI da sua instituição a necessidade de liberação deste remetente.</p></span>
        </h5>
      </v-col>
      </v-row> -->

      <!-- <v-row v-if="message1Visible" align="center" justify="center">
      <v-col>
        <h5 class="text-center white--text" style="background-color: black; line-height: 2.0; margin-left: 10px; margin-right: 10px;"> <img src="@/pad_ico_atencao_10x10.png" alt="Ícone" class="mr-2" style="height: 15px; width: 15px; vertical-align: middle;"> <strong>ATUALIZAÇÃO:</strong> 
        <span style="font-weight: normal;"> Informamos que iremos realizar uma manutenção em nossos sistemas que pode afetar temporariamente o acesso aos</span> 
        <span style="font-weight: normal;"> <p>dashboards para alguns usuários. Este procedimento é necessário para garantir a qualidade e aprimoramento dos nossos serviços.</p></span>
        <span style="font-weight: normal;"> ● Início: 09/04/2024 às 20:00h</span>
        <span style="font-weight: normal;"> <p>● Estimativa de término: 09/04/2024 até às 20:40h</p></span>
        <span style="font-weight: normal;"> <p>Durante este período, os dashboards podem apresentar instabilidade e/ou indisponibilidade.</p></span>
        <span style="font-weight: normal;"> <p>Agradecemos sua compreensão e estamos trabalhando para melhorar nossos sistemas.</p></span>
        <span style="font-weight: normal;"> Atenciosamente,</span>
        <span style="font-weight: normal;"> <p>Equipe Qwst,</p></span>
        <button v-on:click="message1Visible = false"><b>[X] Fechar mensagem</b></button>
        </h5>
      </v-col>
      </v-row> -->

      <v-row
        align="center"
        justify="center"
        v-if="
          this.$store.state.trustedUrl === null &&
          (this.$store.state.folder === undefined ||
            this.$store.state.view === undefined)
        "
      >
        <v-col cols="auto">
          <h2>Selecione um dashboard no menu lateral</h2>
        </v-col>
      </v-row>
      <router-view v-else />
    </v-content>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}
      <v-btn text outlined @click="snackbar.active = false">Fechar</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import Bucket from "@/repositories/bucket";
import { mapState, mapActions } from "vuex";
import Amplify from "aws-amplify";
import Cognito from "../../store/cognito";

const client = process.env.VUE_APP_CLI;

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_UPD,
    region: "us-east-1",
    userPoolWebClientId: client,
  },
});

export default {
  data: () => ({
    active: [],
    item: 1,
    valid: true,
    validCode: true,
    codeValue: "",
    snackbar: {
      active: false,
      text: "",
      color: "",
      timeout: 4000,
    },
    pwd: "",
    pwdConfirm: "",
    resetStep: "new-pwd-confirm",
    resetPwdDialog: false,
    drawer: {
      model: null,
      type: "default (no property)",
      clipped: false,
      floating: false,
      mini: false,
    },
    items: [],
    header: null,

    urlFileDownload: null, // Início Funções do Bucket
    itemNew: null,
    startScreenLogoUrl: null,
    message1Visible: true,
    

  }),
  computed: {
    ...mapState("Cognito", ["client", "userAuthenticated"]),
    ...mapState(["urlTableau", "domain"]),
    colors() {
      return this.$store.state.colors;
    },
    passwordConfirmationRule() {
      return () => this.pwd === this.pwdConfirm || "As senhas não estão iguais";
    },

    cantEmptyRule() {
      return (v) => !!v || "Campo não pode ficar vazio!";
    },
    passwordValidRule() {
      return () =>
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
          this.pwd
        ) ||
        "A senha contém pelo menos um caractere especial, um número, uma letra maiúscula e uma minúscula";
    },
    menuLogoPath() {
      return this.$store.state.colors.menuLogo;
    },
    menuLogo() {
      return this.menuLogoPath
        ? Bucket.getSignedUrl(this.menuLogoPath, "")
        : Bucket.getSignedUrl("common/logo_qwst_dash.png", "");
    },
    model() {
      const home = {
        id: Math.random(),
        icon: "mdi-home",
        type: "home",
        path: "/home",
        name: "Início",
      };
      if (this.$store.state.dashboardPanel) {
        const model = { ...this.$store.state.dashboardPanel };
        if (model.children[0].type !== "home") {
          model.children.unshift(home);
        }
        return this.$store.state.dashboardPanel;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("Cognito", ["SIGNOUT", "CURRENT_AUTHENTICATED_USER"]),
    ...mapActions(["tableauUrl"]),
    activateSnackbar(text, color, timeout) {
      this.snackbar = { ...this.message, active: true, text, color, timeout };
    },

    //------------Início---------------
    async loadurlFileDownload(item) {
      try {
        // Simulação de uma chamada assíncrona, por exemplo, uma requisição HTTP

        const url = await Bucket.getSignedUrl(
          this.$store.state.dashboardId,
          item
        );

        this.urlFileDownload = url;
        return url;
            
      } catch (error) {
        console.error('Erro na chamada assíncrona', error);
      }
    },


    // Obtem o Logo Principal
    async loadStartScreenLogo() {
      if (this.startScreenLogoUrl !== null) {
        return
      }
      try {
        // Simulação de uma chamada assíncrona, por exemplo, uma requisição HTTP
        const signedUrl = await Bucket.getSignedUrl(this.startScreenPath, "");
        this.startScreenLogoUrl = signedUrl;
      } catch (error) {
        console.error('Erro na chamada assíncrona', error);
      }
    },

    expandMenu() {
      // Expandir a treeview
      this.drawer.model = true;
    },

    //------------Fim------------------



    openDialog() {
      this.resetStep = "new-pwd-confirm";
      this.resetPwdDialog = true;
    },
    closeDialog() {
      this.resetPwdDialog = false;
    },
    sendCode() {
      Amplify.Auth.forgotPassword(this.userAuthenticated.attributes.email)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      this.resetStep = "code";
    },
    changePwd() {
      Amplify.Auth.forgotPasswordSubmit(
        this.userAuthenticated.attributes.email,
        this.codeValue,
        this.pwdConfirm
      )
        .then((data) => {
          //console.log(data);
          this.resetPwdDialog = false;
          this.resetStep = "new-pwd-confirm";
          this.activateSnackbar("Senha alterada com sucesso!", "success");
        })
        .catch((err) => {
          console.log(err);
          if (
            err.message ===
            "Invalid verification code provided, please try again."
          ) {
            this.activateSnackbar(
              "Código inválido, por favor tente novamente.",
              "error"
            );
          } else if (
            err.message ===
            "Attempt limit exceeded, please try after some time."
          ) {
            this.activateSnackbar(
              "Limite de tentativas excedido, aguarde para tentar novamente.",
              "error"
            );
          } else {
            this.activateSnackbar(err.message, "error");
          }
        });
    },
    logOut() {
      this.SIGNOUT().then(() => {
        this.$router.push({ path: `/${this.domain}/login` });
      });
    },
    // iframe(folder, view) {
    //   this.$store.commit("generateUrl", { folder, view });
    // },
    click(active) {
      // this.$ga.set("dimension1", this.$store.state.dashboardId);
      // this.$ga.event({
      //   eventCategory: "Menu",
      //   eventAction: this.userAuthenticated.attributes.email,
      //   eventLabel: active[0].id,
      // });
      this.message1Visible = false;
      const item = active[0];
      // console.log(item.type)
      // console.log('item', item)
      if (item.type === "home") {
        this.$router.push({ path: item.path });
      } else if (item.type === "tableauButton") {
        this.CURRENT_AUTHENTICATED_USER();

        this.$store.commit("getTableau", {
          folder: item.folder,
          view: item.view,
        });
        // console.log(this.$store.state.folder, this.$store.state.view)
        this.tableauUrl({
          folder: this.$store.state.folder,
          view: this.$store.state.view,
        });
        // console.log('trusted url', this.$store.state.urlTableau)

        // this.iframe(item.folder, item.view);
        this.header = item.name;
      } else if (item.type === "downloadButton") {
        // console.log('dashid', this.$store.state.dashboardId)
        this.CURRENT_AUTHENTICATED_USER();
        
        // let url = Bucket.getSignedUrl(
        //   this.$store.state.dashboardId,
        //   item.url.value
        // );
        // window.open(url);

        this.itemNew = item.url.value;
        this.loadurlFileDownload(item.url.value);
        setTimeout(() => {
          window.open(this.urlFileDownload);
        }, 4000);


      } else if (item.type === "linkButton") {
        this.CURRENT_AUTHENTICATED_USER();
        let pad_email = Cognito.state.userAuthenticated.attributes.email;
        let finalUrl = `${item.url}?pad_email=${pad_email}`;
        this.header = item.name;
        this.$store.state.trustedUrl = finalUrl;
      }
    },
  },
  watch: {
    model: {
      handler(value) {
        if (!value) this.$router.push({ path: "/home" });
      },
      immediate: true,
    },
    project(value) {
      if (value) {
        this.$store.commit("setColor", value.colors);
      }
    },
  },
  mounted() {
    const bind = this;
    bind.CURRENT_AUTHENTICATED_USER();
    // bind.$store.commit("defaultColors")
    bind.$store.commit("getTableau", {
      folder: bind.model.children[bind.item].folder,
      view: bind.model.children[bind.item].view,
    });
    bind.tableauUrl({
      folder: bind.$store.state.folder,
      view: bind.$store.state.view,
    });

    bind.header = bind.model.children[bind.item].name;

    setTimeout(() => {
     this.expandMenu();
    }, 500); // Ajuste o intervalo conforme necessário
    
  },
  beforeDestroy() {
    this.$store.commit("defaultColors");
    this.$store.state.model = null;
  },
};
</script>

<style lang="scss" scoped>
#sandbox {
  width: 100% !important;
}

#content_panel {
  padding: 0 !important;
}

.bgDialog {
  background-color: #ffffff;
}
</style>